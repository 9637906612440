.greeting-text{
	@media screen and (min-width:768px) {
		font-size: 19px;
	}
}
.greeting-author{
	margin-top: 4em;
	font-size: 14px;
	border: 1px solid #666;
	position: relative;
	padding: 1em;
	p{margin-top: 0;}
	@media screen and (min-width:768px) {
		font-size: 16px;
	}
}
.greeting-author__ttl{
	position: absolute;
	display: inline-block;
	padding: 0 .75em;
	background-color: #fff;
	top: -.75em;
}
.business-item__wrap{
	display: flex;
	flex-direction: column;
	align-items: center;
	@media screen and (min-width:768px) {
		flex-direction: row;
		justify-content: space-between;
		padding: 0 2em;
		.col{
			width: 250px;
			margin: 0 1.5em;
			flex: 0 0 250px;
		}
	}

}
.business-item{
	margin-top: 2.5em;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media screen and (min-width:768px) {

	}
	img{
		width: 15%;
	}
	.ttl{
		margin-top: 0.5em;
		font-size: 20px;
	}
	p{
		font-size: 15px;
		margin-top: 1em;
		@media screen and (min-width:768px) {
			font-size: 17px;
			padding-left: 0.3em;
		}
	}
	&.item1{
		.ttl{
			color: #00639E;
		}
	}
	&.item2{
		.ttl{
			color: #367C47;
		}
	}
	&.item3{
		.ttl{
			color: #C58540;
		}
	}
}
.privacy-txt{
	.ttl{
		margin-top: 2em;
		margin-bottom: 0.25em;
	}
	.txt{
		margin-top: 0.5em;
	}
}
