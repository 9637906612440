.about-mv{
	background-image: url(/img/about-mv-bg.jpg);
}
.about-gmap{
	height: 425px;
	margin: auto;
	iframe{
		width: 100%;
		height: 100%;
	}
}
.about-outline{

	&--table{
		width: 100%;
		border-collapse: collapse;
		@media screen and (min-width:768px) {
			font-size: 18px;
		}
		th,td{
			text-align: left;
			display: block;
			padding-top: .7em;
			@media screen and (min-width:768px) {
				display: table-cell;
				font-size: 17px;
				padding-bottom: 2em;
			}
		}
		th{
			margin-top: 1em;
			@media screen and (min-width:768px) {
				font-weight: normal;
				padding-right: 4em;
			}
		}
		td{
			padding-top: 0.3em;
		}
	}
}
