.mv{
	background-position: center center;
	background-size: cover;
	&.top-mv{
		background-image: url(../img/top-mv.png);
	}
	&.greeting-mv{
		background-image: url(../img/greeting-mv.png);
	}
	&.business-mv{
		background-image: url(../img/business-mv.png);
	}
	&.outline-mv{
		background-image: url(../img/outline-mv.png);
	}
	&.contact-mv{
		background-image: url(../img/contact-mv.png);
	}
	&.privacy-mv{
		background: transparent radial-gradient(closest-side at 50% 56%, #9BAEBE 0%, #002D51 100%) 0% 0% no-repeat padding-box;
	}
	.mv-inner{
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 2em;
		min-height: 120px;
		@media screen and (min-width:768px) {
			padding: 5em 0 5em;
		}
		.logo{
			width: 40%;
			@media screen and (min-width:768px) {
				width: 205px;
			}
		}
		.text{
			margin-top: 1em;
			text-align: center;
			color: #fff;
			font-size: 18px;
			p{
				margin-top: 0.2em;
				line-height: 1.6;
				text-shadow: 0 0 5px rgba(0,0,0,1);
			}
			.big{
				font-size: 1.2em;
				font-weight: bold;
			}

		}
	}
}

$logo-delay: 2.5s;
.card {
  position : relative;
  -webkit-perspective : 1000px;
  perspective : 1000px;
  .front,.back {
    width : 100%;
    height : 100%;
    // cursor : pointer;
    transition : all $logo-delay;
    -webkit-backface-visibility : hidden;
    backface-visibility : hidden;
  }
  .front {
    opacity: 0;
  }
  .back {
		opacity: 0;
		background: rgba(0,0,0,0);
    transform : rotateY(-180deg);
  }
}

.flipped {
  .front {
    transform : rotateY(180deg)
  }
  .back {
		opacity: 1;
    transform : rotateY(0);
  }
}
.fadeUp{
	animation-name:fadeUpAnime;
	animation-delay: $logo-delay;
	animation-duration:1.8s;
	animation-fill-mode:forwards;
	opacity:0;
	&.fadeUp2{
		animation-delay: $logo-delay+ 1;
	}
	}

	@keyframes fadeUpAnime{
		from {
			opacity: 0;
		transform: translateY(50px);
		}

		to {
			opacity: 1;
		transform: translateY(0);
		}
	}
