.c-sectionTtl{
	color: $color-brand;
	position: relative;
	margin-bottom: 1.5em;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: flex-end;
	&::after{
		content: "";
		display: block;
		flex: 0 0 100%;
		width: 100%;
		height: 4px;
		background: transparent linear-gradient(90deg, #5DB7FF 0%, #0F0498 38%, #76BECB 61%, #DFF0DC 84%, #FFF4BA 100%) 0% 0% no-repeat padding-box;
	}
	.main{
		font-size: 26px;
		margin-top: 1em;
		@media screen and (min-width:768px) {
			font-size: 37px;
		}
	}
	.small{
		font-size: 12px;
		display: block;
		text-align: right;
		.date{display: inline-block;}
		.date2{display: inline-block;padding-left: 1em;}
		@media screen and (min-width:768px) {
			font-size: 14px;
		}
	}
	&__center{
		.main{
			text-align: center;
		}
	}
}
.c-pageTtl{
	background: rgba($color: #fff, $alpha: .5);
	padding: 1em;
	text-align: center;
	margin: auto;
	max-width: 475px;
	font-size: 26px;
	@media screen and (min-width:768px) {
		font-size: 37px;
	}
}
