.works-mv{
	background-image: url(/img/works-mv-bg.jpg);
}
.works-content{

	max-width: 244px;
	img{
	}
	p{
		text-align: center;
		@media screen and (min-width:768px) {
			font-size: 20px;
		}
	}
}
.works-mainTxt{
	margin-top: 2em;
	@media screen and (min-width:768px) {
		font-size: 23px;
	}
}

.works-bridge{
	margin-top: 4rem;
	p{
		text-align: center;
		margin-top: 1rem;
	}
	&-sp{
		display: block;
		@media screen and (min-width:567px) {
			display: none;
		}
	}
	&-pc{
		display: none;
		@media screen and (min-width:568px) {
			display: block;
		}
	}
	.works-bridge-txt{
		max-width: 578px;
		text-align: center;
		margin: 4rem auto 0;
	}
}
