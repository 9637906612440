.c-mv{
	padding: 80px 0;
	background-size: cover;
	background-position: center bottom;
}
.c-list{
	li{
		display: flex;
		flex-direction: column;
		margin-top: 1.5em;
		@media screen and (min-width:768px) {
			flex-direction: row;
			margin-top: 2em;
			.date{
				padding-right: 160px
			}
			.detail{
				padding-right: 60px
			}
			.link{}
		}
		.link{
		}
	}
}
