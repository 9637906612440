.privacy-mv{
	background-color: rgba($color: $color-brand, $alpha: .2)
}
.privacy-contents{
	max-width: 740px;
	margin: auto;
	.ttl{
		margin: 20px 0;
	}
}
