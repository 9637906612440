.u-sp-only{
	&_db{
		display: block;
		@media screen and (min-width:768px) {
			display: none;
		}
	}
	&_dib{
		display: inline-block;
		@media screen and (min-width:768px) {
			display: none;
		}
	}
}
.u-pc-only{
	&_db{
		display: block;
		@media screen and (max-width:767px) {
			display: none;
		}
	}
	&_dib{
		display: inline-block;
		@media screen and (max-width:767px) {
			display: none;
		}
	}
}
.u-dib{
	display: inline-block;
}
.u-ta-r{
	text-align: right;
}
