main{
	padding-bottom: 4rem;
}
.l-wrapper{
		overflow: hidden;
		display: flex;
		flex-direction: column;
		min-height: 100vh;
}
.l-inner{
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 1em;
	padding-right: 1em;
	@media screen and (min-width:768px) {
		padding-left: 0;
		padding-right: 0;
	}
}
.l-header{
	z-index: 9999;
}
.l-footer{
	margin-top: auto;
}
.l-container{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-right: -15px;
	margin-left: -15px;
}
.l-col{
	padding: 0 15px;
	@media screen and (min-width:768px) {
		padding: 0 30px;
	}
}
